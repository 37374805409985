const citations = [
  {
    "citation": "Digital Realty Trust, Inc. v. Somers, 138 S. Ct. 767 (2018)",
    "summary": "In Digital Realty Trust, Inc. v. Somers, the Supreme Court held that the Dodd-Frank Act's anti-retaliation provisions do not extend to individuals who have not reported a securities-law violation to the SEC. The case involved Paul Somers, who alleged that he was fired after reporting securities law violations at Digital Realty to senior management but not to the SEC. The Court emphasized that the statutory definition of 'whistleblower' explicitly requires reporting to the SEC, aligning with the purpose of the Dodd-Frank Act to encourage reporting to the federal government.",
    "id": "1",
  },
  {
    "citation": "Gideon v. Wainwright, 372 U.S. 335 (1963)",
    "summary": "Gideon v. Wainwright was a landmark decision in which the Supreme Court of the United States unanimously ruled that states are required under the Sixth Amendment to provide an attorney to defendants in criminal cases who cannot afford their own attorneys. The case arose when Clarence Earl Gideon was charged with a felony in a Florida state court and, lacking funds, requested the court appoint him legal counsel. The court denied his request, leading to his conviction, but the Supreme Court overturned the decision, establishing the principle that the right to counsel is fundamental and essential for a fair trial.",
    "id": "2",
  },
  {
    "citation": "New York Times Co. v. Sullivan, 376 U.S. 254 (1964)",
    "summary": "In New York Times Co. v. Sullivan, the Supreme Court held that public officials seeking damages for defamation must prove that the statement was made with 'actual malice,' meaning knowledge of its falsity or with reckless disregard for the truth. This landmark decision established a higher standard of proof for defamation cases involving public figures, significantly expanding First Amendment protections for the press. The ruling emphasized the need for 'uninhibited, robust, and wide-open' debate on public issues, ensuring that potential errors in reporting do not deter freedom of expression.",
    "id": "3",
  },
  {
    "citation": "Bowers v. Hardwick, 478 U.S. 186 (1986)",
    "summary": "In Bowers v. Hardwick, the Supreme Court addressed the constitutionality of a Georgia sodomy statute criminalizing private consensual homosexual activity. The Court ultimately upheld the statute, reasoning that there was no constitutionally protected right to engage in homosexual sodomy. The decision emphasized that proscriptions against such conduct were deeply rooted in American history and tradition.",
    "id": "4",
  },
  {
    "citation": "Katz v. United States, 389 U.S. 347 (1967)",
    "summary": "In Katz v. United States, 389 U.S. 347 (1967), the Supreme Court held that the Fourth Amendment protects people, not places, creating the legal standard that the amendment applies to any situation where an individual has a 'reasonable expectation of privacy.' The case arose after federal agents had eavesdropped on public payphone conversations without a warrant, leading to the defendant's conviction. The Court ultimately determined that such warrantless wiretaps were unconstitutional, emphasizing the need for judicial oversight when it comes to searches and seizures.",
    "id": "5",
  },
  {
    "citation": "Brown v. Board of Education, 347 U.S. 483 (1954)",
    "summary": "In Brown v. Board of Education, the Supreme Court held that racial segregation in public schools violated the Equal Protection Clause of the Fourteenth Amendment. The unanimous decision stated that 'separate educational facilities are inherently unequal,' effectively overturning the precedent established in Plessy v. Ferguson (1896) that allowed for 'separate but equal' facilities. This landmark ruling mandated the desegregation of schools across America, signaling a pivotal moment in the Civil Rights Movement.",
    "id": "6",
  },
  {
    "citation": "United States v. Windsor, 570 U.S. 744 (2013)",
    "summary": "The Supreme Court ruled that Section 3 of the Defense of Marriage Act (DOMA) was unconstitutional as it deprived same-sex couples legally married under state law of their Fifth Amendment rights to equal protection. The case emerged when Edith Windsor's marriage to Thea Spyer was not recognized by the federal government, leading to a large tax bill after Spyer's death. The decision facilitated federal recognition of same-sex marriages, affecting more than 1,000 federal statutes and programs linked to marital status.",
    "id": "7",
  },
  {
    "citation": "Roe v. Wade, 410 U.S. 113 (1973)",
    "summary": "Roe v. Wade, decided in 1973, was a landmark decision by the United States Supreme Court on the issue of abortion. The Court ruled that a woman's right to choose to have an abortion is protected under the constitutional right to privacy, which is found in the Due Process Clause of the Fourteenth Amendment. The decision effectively invalidated many state and federal restrictions on abortion, reshaping women's reproductive rights across the United States.",
    "id": "8",
  },
  {
    "citation": "Brown v. Board of Education, 347 U.S. 483 (1954)",
    "summary": "This landmark decision by the United States Supreme Court declared state laws establishing separate public schools for black and white students to be unconstitutional. The Court's unanimous (9-0) decision stated that 'separate educational facilities are inherently unequal,' and therefore violate the Equal Protection Clause of the Fourteenth Amendment. This case effectively overturned the Plessy v. Ferguson decision of 1896, which allowed state-sponsored segregation under the 'separate but equal' doctrine.",
    "id": "9",
  },
  {
    "citation": "Brown v. Board of Education, 347 U.S. 483 (1954)",
    "summary": "Brown v. Board of Education was a landmark Supreme Court case in which the doctrine of 'separate but equal' in public education was declared unconstitutional. The Court unanimously decided that separate educational facilities for racial minorities and whites were inherently unequal, thus violating the Equal Protection Clause of the Fourteenth Amendment. This decision was pivotal in the fight against racial segregation in the United States, leading to increased momentum in the Civil Rights Movement.",
    "id": "10",
  }
]

const md = {
  userId: '84083408-a051-70d1-f910-749d49645793',
  caseId: "69486519",
  documentName: "billOfParticulars_Crr7_5United_States_v_Mangione2025-01-09_clean_clean_clean_clean",
  motionId: "6261b190-150d-4317-b3b1-1004cf75cb7d",
  use: false,
  citations: citations,
}

export default md;