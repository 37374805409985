import React, { useState, useContext, useRef } from 'react';
import GenerateMotionModalPage from './GenerateMotionModalPage';
import GenerateMotionContext from '../../GenerateMotionContext';
import Button from '@Shared/Button/Button';
import { ReactComponent as DownloadIcon } from '@icons/download-file.svg';
import { generatePresignedURL, getFileUrl } from '@lib/utils/awsSdkHelper';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const MotionModalPage4 = ({ pageIndex, documentName, caseId, downloadUrl }) => {
  const { selectedMotion, downloadMotion } = useContext(GenerateMotionContext);
  const [checked, setChecked] = useState(false);
  const blobButtonRef = useRef(null);
  const user = useSelector((state) => state.user);

  // const fixUrl = (url) => {
  //     try {
  //         let decodedUrl = decodeURIComponent(url);
  //         const s3BaseUrlRegex = /https:\/\/[\w.-]+\.s3[\w.-]*\.amazonaws\.com/;
  //         const match = decodedUrl.match(s3BaseUrlRegex);
  //         if (!match) {
  //             throw new Error("Valid S3 base URL not found in the malformed URL.");
  //         }
  //         const baseUrl = match[0];
  //         const cleanedUrl = decodedUrl.substring(decodedUrl.lastIndexOf(baseUrl));
  //         const fixedUrl = decodeURIComponent(cleanedUrl);
  //         return fixedUrl;
  //     } catch (error) {
  //         console.error("Failed to fix the URL:", error.message);
  //         return null;
  //     }
  // }
      

  // const fetchPresignedUrl = async () => {
  //   try {
  //     const url = await generatePresignedURL(key, caseId);
  //     setDownloadUrl(url);
  //     return url;
  //   } catch (error) {
  //     console.log("Error fetching presigned URL:", error);
  //   }
  // };

  // const downloadMotionTemp = async () => {
  //   const downloadMotionUrl = await fetchPresignedUrl();
  //   if (downloadMotionUrl) {
  //     console.log("Downloading motion from URL:", downloadMotionUrl);
  //     const url = window.URL.createObjectURL(downloadMotionUrl);

  //     window.location.href = downloadUrl;
  //   } else {
  //     console.error("Download URL not available.");
  //   }
  // };

  const setBlobUrl = async (e) => {
    setChecked(e.target.value);
    const key = `${documentName}.docx`;
    console.log("key", key);
    console.log("caseId", caseId);
    console.log("user.cognito_user_id", user.cognito_user_id);
    const downloadMotionUrl = await getFileUrl(key, user.cognito_user_id, caseId);
    if (downloadMotionUrl) {
      console.log("Downloading motion from URL--:", downloadMotionUrl);
      setDownloadUrl(downloadMotionUrl);
      blobButtonRef.current.href = downloadMotionUrl;
      blobButtonRef.current.download = selectedMotion?.name;
    } else {
      console.error("Download URL not available.");
    }
  }

  return (
    <GenerateMotionModalPage title="Atticus AI is done!" pageIndex={pageIndex}>
      <p>Your motion has been successfully generated. Please review this draft {selectedMotion?.name}.</p>
      <p>
        Note that the following does not constitute legal advice, and it is the responsibility of the attorney representing the client to review all generated
        content thoroughly.
      </p>
      <p>Confirm to Get Motion</p>
      <div class="download-motion-confirm-container">
        <form>
          <input name="checked" type="checkbox" id="download-motion-agree-checked" value={checked}></input>
          <label htmlFor="download-motion-agree-checked">
            I confirm that I have reviewed the draft and understand that the onus of responsibility for reviewing all generated content is upon the individual
            attorney representing a client.
          </label>
        </form>
        <Link to={downloadUrl} ref={blobButtonRef} download>
          <Button className={`button--outline download-motion-button ${!checked ? 'motion-download-disabled' : ''}`}>
            <DownloadIcon />
            {selectedMotion?.name}
          </Button>
        </Link>
      </div>
    </GenerateMotionModalPage>
  );
};

export default MotionModalPage4;
