import { authTypes } from '../types';

const initialState = {
  access: localStorage.getItem('access') || null,
  access_expiration: localStorage.getItem('access_expiration') || null,
  refresh: localStorage.getItem('refresh') || null,
  refresh_expiration: localStorage.getItem('refresh_expiration') || null,
  isAuthenticated: localStorage.getItem('isAuthenticated') === "true" || false,
  needToConfirmEmail: localStorage.getItem('needToConfirmEmail') === "true" || false,
  chatToken: localStorage.getItem('chatToken') || null,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.REGISTER_EMAIL:
      return action.payload;
    case authTypes.COFIRM_EMAIL:
      return action.payload;
    case authTypes.LOG_IN:
      return action.payload;
    case authTypes.LOG_OUT:
      return null;
    default:
      return state;
  }
}

export default authReducer;
