import React, { useEffect, useState } from 'react';
import TextField from '@Shared/TextField/TextField';
import Button from '@Shared/Button/Button';
import DatePicker from '@Shared/DatePicker/DatePicker';
import './NewSearchCase.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoaderSpinner from '@Shared/LoaderSpinner/LoaderSpinner';
import { createCase } from '@lib/apis/resources/case';
import { useSelector } from 'react-redux';
import { isUserObjectAssignedToCaseId } from '@lib/utils/caseHelpers';
import { addCaseToUser } from '@lib/apis/resources/user';

const NewSearchCase = ({ onClose, caseDetails }) => {
  const user = useSelector((state) => state.user);
  const { cognito_user_id: userId, email } = user;
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Loading');
  const [formData, setFormData] = useState({
    partyName: '',
    court: '',
    caseNumber: '',
    docketEntries: '',
    dateFiled: null,
    dateClosed: null,
    docketId: '',
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (caseDetails) {
      setFormData({
        partyName: caseDetails.case_name || '',
        court: caseDetails.court_id || '',
        caseNumber: caseDetails.docket_number || '',
        docketEntries: caseDetails.court_id || '',
        dateFiled: caseDetails.date_filed ? new Date(caseDetails.date_filed) : null,
        dateClosed: caseDetails.date_terminated ? new Date(caseDetails.date_terminated) : null,
        docketId: caseDetails.docketId || '',
      });
    }
  }, [caseDetails]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDateChange = (name, date) => {
    setFormData({ ...formData, [name]: date });
  };

  const handleCancel = (e) => {
    setFormData({
      partyName: '',
      court: '',
      caseNumber: '',
      docketEntries: '',
      dateFiled: null,
      dateClosed: null,
    });
    onClose();
  };

  const handleAddCase = async () => {
    if (!formData.docketId) {
      toast.error('Docket ID is missing');
      return;
    }
    setLoading(true);
    try {
      const caseId = formData?.docketId;
      const isAssigned = await isUserObjectAssignedToCaseId(caseId, email);
        if (isAssigned) {
          toast.info('This case is already assigned to you.')
          navigate('/dashboard/cases');
          return;
        }
      const createCaseResponse = await createCase(userId, formData.docketId, loadingMessage, setLoadingMessage);
      console.log('createCaseResponse', createCaseResponse);
      const addCaseToUserResponse = await addCaseToUser(userId, caseId);
      console.log('addCaseToUserResponse', addCaseToUserResponse);

      if (createCaseResponse.success && addCaseToUserResponse.success) {
        navigate(`/dashboard/cases/${formData.docketId}/docketupload`);
      } else {
        toast.error('Unable to add a case. Please wait and try again. If this issue persists, please contact support at help@atticusai.org');
      }
    } catch (error) {
      toast.error('Unable to add a case. Please wait and try again. If this issue persists, please contact support at help@atticusai.org');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="new-search-case-modal">
      <div className="new-search-case-group">
        <label htmlFor="partyName">Party Name</label>
        <TextField placeholder="Party Name" name="partyName" value={formData.partyName} onChange={handleChange} className="new-search-case-input" disabled />
      </div>
      <div className="new-search-case-group">
        <label htmlFor="court">Court</label>
        <TextField placeholder="Court" name="court" value={formData.court} onChange={handleChange} className="new-search-case-input" disabled />
      </div>
      <div className="new-search-case-group">
        <label htmlFor="caseNumber">Case Number Full</label>
        <TextField
          placeholder="Case Number Full"
          name="caseNumber"
          value={formData.caseNumber}
          onChange={handleChange}
          className="new-search-case-input"
          disabled
        />
      </div>
      <div className="new-search-case-group">
        <label htmlFor="docketEntries">Number of Docket Entries</label>
        <TextField
          placeholder="Number of Docket Entries"
          name="docketEntries"
          value={formData.docketEntries}
          onChange={handleChange}
          className="new-search-case-input"
          disabled
        />
      </div>
      <div className="new-search-case-date-picker-group">
        <div className="new-search-case-group">
          <label htmlFor="dateFiled">Date Filed</label>
          <DatePicker
            placeholder="Date Filed"
            name="dateFiled"
            value={formData.dateFiled}
            selected={formData.dateFiled}
            onChange={(date) => handleDateChange('dateFiled', date)}
            disabled={true}
          />
        </div>
        <div className="new-search-case-group">
          <label htmlFor="dateClosed">Date Closed</label>
          <DatePicker
            placeholder="Date Closed"
            name="dateClosed"
            value={formData.dateClosed}
            selected={formData.dateClosed}
            onChange={(date) => handleDateChange('dateClosed', date)}
            disabled={true}
          />
        </div>
      </div>
      <Button className="button--primary button--full-width" onClick={handleAddCase}>
        {loading ? (
          <div className="search-case-loader-spinner-container">
            <LoaderSpinner
              width="22"
              color="#000000"
              interval={5000}
              messages={['Loading', 'Waiting for response.', 'Checking database.', 'Processing request.']}
            />
          </div>
        ) : (
          'Add Case'
        )}
      </Button>
      <Button className="" onClick={handleCancel}>
        Cancel
      </Button>
    </div>
  );
};

export default NewSearchCase;
