import React, { useContext } from 'react';
import GenerateMotionContext from '../GenerateMotionContext';
import { truncate } from '@lib/utils/general';

const CaseRecommendationPannel = ({ caseRecommendation }) => {
  const { selectedCaseRecommendations, setSelectedCaseRecommendations } =
    useContext(GenerateMotionContext);

  const caseIsSelected = () => {
    for (let i = 0; i < selectedCaseRecommendations.length; i++) {
      if (selectedCaseRecommendations[i].id === caseRecommendation.id) {
        return true;
      }
    }
  };

  const addCaseRecommendation = () => {
    console.log('selectedCaseRecommendations', selectedCaseRecommendations);
    console.log('caseRecommendation', caseRecommendation);
    setSelectedCaseRecommendations([
      ...selectedCaseRecommendations,
      caseRecommendation,
    ]);
  };

  const removeCaseRecommendation = () => {
    console.log('selectedCaseRecommendations', selectedCaseRecommendations);
    console.log('caseRecommendation', caseRecommendation);
    let newArray = selectedCaseRecommendations.filter(
      (item) => item.id !== caseRecommendation.id
    );
    setSelectedCaseRecommendations(newArray);
  };

  const toggleSelectedCaseRecommendation = () => {
    if (caseIsSelected()) {
      removeCaseRecommendation();
    } else {
      addCaseRecommendation();
    }
  };

  return (
    <div
      className={`case-recommendation-panel-container ${
        caseIsSelected() ? 'isSelected' : ''
      }`}
      onClick={toggleSelectedCaseRecommendation}
    >
      <div className="case-recommendation-panel">
        {caseRecommendation.citation && (
          <p className="case-recommendation-name">{caseRecommendation.citation}</p>
        )}
        {caseRecommendation.summary && (
          <p className="case-recommendation-description">
            {truncate(caseRecommendation.summary)}
          </p>
        )}
      </div>
    </div>
  );
};

export default CaseRecommendationPannel;
