import React, { useContext } from 'react';
import GenerateMotionModalPage from './GenerateMotionModalPage';
import SharedButton from '@Shared/Button/Button';
import CaseRecommendationPannel from '../CaseRecommendationPannel';
import GenerateMotionContext from '../../GenerateMotionContext';
import { hallucinationDetection } from '@lib/apis/pacer-api';
import md from '@lib/mocks/motionGenerationMockData';
const MotionModalPage2 = ({ handleClose, pageIndex, advancePage, citations, user, caseId, motionId, documentName, setDownloadUrl, setLoading }) => {
  const { setCurrentPageIndex } = useContext(
    GenerateMotionContext
  );

  const mockApiCall = async () => {
    console.log("Getting hallucinationDetection api response with mock data", md);
    const response =  await hallucinationDetection(md.userId, md.caseId, md.motionId, md.documentName, md.citations);
    console.log("response from hallucinationDetection: ", response);
    setDownloadUrl(response.data.download_url);
  }

  const handleOnClick = async () => {
    setLoading(true);
    if (md.use) {
      mockApiCall();
    } else {
      console.log("calling hallucinationDetection with arguments: ", user.cognito_user_id, caseId, motionId, documentName, citations);
      const response =  await hallucinationDetection(user.cognito_user_id, caseId, motionId, documentName, citations);
      console.log("-------response from hallucinationDetection: --------", response);
      console.log("response?.data", response?.data);
      setDownloadUrl(response.data.download_url);
    }
    setCurrentPageIndex(2);
    setTimeout(() => {
      setLoading(false);
      setCurrentPageIndex(3);
    }, 4000);
  }
  return (
    <GenerateMotionModalPage
      title="Case Recommendations"
      handleClose={handleClose}
      pageIndex={pageIndex}
    >
      {citations.map((recommendation, index) => (
        <CaseRecommendationPannel
          key={index}
          name={recommendation.citation}
          description={recommendation.summary}
          caseRecommendation={recommendation}
        />
      ))}
      <SharedButton
        className="button--primary motion-advance-button"
        onClick={handleOnClick}
      >
        Continue
      </SharedButton>
    </GenerateMotionModalPage>
  );
};

export default MotionModalPage2;
