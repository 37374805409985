import React, { useState } from 'react';
import Button from '@Shared/Button/Button';

// This is just a test page to test API calls with a button
const TestPage = () => {
  const [input, setInput] = useState("");

  const handleClick = async () => {
    console.log("response")
  };

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  return (
    <div>
      <h1>Test Page</h1>
      <form>
        <input type="text" value={input} onChange={handleChange} />
      </form>
      <Button onClick={handleClick}>Test Button</Button>
    </div>
  );
};

export default TestPage;
