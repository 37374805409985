import React, { useState, useEffect, useRef } from 'react';
import './Motions.css';
import Button from '@Shared/Button/Button';
import MotionOptionsSelect from './MotionOptionsSelect';
import { ReactComponent as DownloadFile } from '@icons/download-file.svg';
import GenerateMotionModal from './GenerateMotionModal/GenerateMotionModal';
import GenerateMotionProvider from './GenerateMotionProvider';
import GenerateMotionButton from './GenerateMotionButton';
import MotionTypeButtons from './MotionTypeButtons';
import LoaderSpinner from '@Shared/LoaderSpinner/LoaderSpinner';
import { useParams } from 'react-router-dom';
import { getAppId } from '@lib/utils/general';
import { useSelector } from 'react-redux';
import { fetchMotion } from '@lib/utils/caseHelpers';
import { cleanupLocalStorage, updateMotionsUI } from '@lib/utils/caseHelpers';

const Motions = ({ setIsRecommendationsFetched }) => {
  const [motionTypes, setMotionTypes] = useState([]);
  const loadingRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [loadingMessage, setLoadingMessage] = useState('Loading');

  const recentMotions = [{ name: 'Motion to Extend Time' }, { name: 'Motion to Suppress Evidence' }];

  const user = useSelector((state) => state.user);
  const { cognito_user_id: userId } = user;
  const caseId = id;
  const appId = getAppId();

  useEffect(() => {
    const savedMotionsKey = `motionTypes_${caseId}`;
    const isFetchedKey = `motionTypesFetched_${caseId}`;
    const savedMotions = JSON.parse(localStorage.getItem(savedMotionsKey));
    const isFetched = localStorage.getItem(isFetchedKey);

    cleanupLocalStorage(caseId);

    if (savedMotions && savedMotions?.length > 0) {
      setMotionTypes(savedMotions);
      setLoading(false);
    } else if (!isFetched) {
      handleRefresh();
    }
  }, [userId, caseId, appId]);

  const handleRefresh = async () => {
    if (loadingRef.current) return;
  
    try {
      loadingRef.current = true;
      setLoading(true);
      
      const motions = await fetchMotion(userId, caseId, appId, loadingMessage, setLoadingMessage);
      const updatedMotions = updateMotionsUI(motions, caseId);
      
      setMotionTypes(updatedMotions);
      setIsRecommendationsFetched(true);
    } catch (error) {
      setIsRecommendationsFetched(false);
    } finally {
      setLoading(false)
      loadingRef.current = false;
    }
  };

  

  return (
    <GenerateMotionProvider>
      <div className="motion-container">
        <h3 className="cases-motion-title">Motions</h3>
        <div className="motion-recommendations-container">
          <div className="motion-recommendations-title-container">
            <h4>Atticus AI Recommends</h4>
            {!loading && (
              <Button className="button--primary retry-atticus-recommends" onClick={handleRefresh}>
                Refresh
              </Button>
            )}
          </div>
          {loading ? (
            <>
              <LoaderSpinner color="#2dc008" interval={10000} messages={['Loading', 'Waiting for response.', 'Checking database.', 'Processing request.']} />
            </>
          ) : (
            <>
              <MotionTypeButtons motionTypes={motionTypes} />
              <MotionOptionsSelect />
              <GenerateMotionButton />
            </>
          )}
        </div>
        <div className="recent-motions-container">
          <h4>Recently Used Motions</h4>
          {recentMotions.map((motion, index) => (
            <div className="recent-motion" key={index}>
              <DownloadFile /> {motion.name}
            </div>
          ))}
        </div>
        <GenerateMotionModal />
      </div>
    </GenerateMotionProvider>
  );
};

export default Motions;
