import React, { useContext, useState } from 'react';
import GenerateMotionModalPage from './GenerateMotionModalPage';
import SharedButton from '@Shared/Button/Button';
import GenerateMotionContext from '../../GenerateMotionContext';
import './MotionModalPages.css';
import { generateDocumentLambda, queryPrecedents, processPrecedents } from '@lib/apis/pacer-api';
import md from '@lib/mocks/motionGenerationMockData';

const MotionModalPage1 = ({ pageIndex, setDocumentName, setMotionId, user, caseId, motionType, citations, setCitations, setLoading }) => {
  const { setCurrentPageIndex, setFormData, formData } = useContext(
    GenerateMotionContext
  );
  const { selectedMotion } = useContext(GenerateMotionContext);
  const [disabled, setDisabled] = useState(true);

  const MINIMUM_QUERY_LENGTH = 5;

  const handleChange = (e) => {
    setFormData({ ...formData, query: e.target.value });
    if (e.target.value.length < MINIMUM_QUERY_LENGTH) {
      setDisabled(true);
    } else {
      if (disabled) {
        setDisabled(false);
      }
    }
  };
 
  const mockApiCall = async () => {
    setDocumentName(md.documentName);
    setMotionId(md.motionId);
    setCitations(md.citations);
  };

  const handleOnClick = async () => {
    const userId = user.cognito_user_id;
    setLoading(true);
    if (md.use) {
      mockApiCall();
    } else {
      // const generateDocumentLambdaResponse = await generateDocumentLambda(user.cognito_user_id, caseId, selectedMotion.value, formData.query);
      console.log("Calling generateDocumentLambda with these parameters: ", userId, caseId, selectedMotion.value, formData.query);
      const generateDocumentLambdaResponse = await generateDocumentLambda(userId, caseId, selectedMotion.value, formData.query);
      console.log("generateDocumentLambdaResponse: ", generateDocumentLambdaResponse);
      const { motionId, documentName } = generateDocumentLambdaResponse.data;
      console.log("Setting documentName and motionId: ", documentName, motionId);
      setDocumentName(documentName);
      setMotionId(motionId);
      const queryPrecedentsResponse = await queryPrecedents(userId, caseId, documentName, motionId);
      const { citations } = queryPrecedentsResponse.data;
      console.log("Calling processPrecedents with these parameters: ", userId, caseId, motionId, documentName, citations);
      const processPrecedentsResponse = await processPrecedents(userId, caseId, motionId, documentName, citations);
      console.log("processPrecedentsResponse: ", processPrecedentsResponse);
      let processedCitations = processPrecedentsResponse.data?.processed_citations;
      processedCitations = processedCitations.map((recommendation, index) => {
        return {...recommendation, id: index}
      });
      setCitations(processedCitations);
    }
    setLoading(false);
    setCurrentPageIndex(pageIndex + 1)
  };

  return (
    <GenerateMotionModalPage
      className="motion-modal-page-1"
      title="Why are you filing this motion?"
      pageIndex={pageIndex}
    >
      <textarea
        value={formData.query}
        onChange={handleChange}
        placeholder="Describe motion's purpose"
      ></textarea>
      <SharedButton
        disabled={disabled}
        onClick={handleOnClick}
        className="button--primary"
      >
        Continue
      </SharedButton>
    </GenerateMotionModalPage>
  );
};

export default MotionModalPage1;
