import React from 'react';
import './LegalBriefSection.css';
import FeatureCard from '@Shared/FeatureCard/FeatureCard';
import pdfIcon from '@icons/pdf-icon.svg';
import legalAnalysisIcon from '@icons/legal-analysis-icon.svg';
import seamlessCollabrationIcon from '@icons/seamless-collaboration-icon.svg';
import arrowDownIcon from '@icons/arrow-down-black.svg';
import rightThreeLinesIcon from '@icons/three-lines-right-icon.svg';
import leftThreeLinesIcon from '@icons/three-lines-left-icon.svg';
import arrowRightIcon from '@icons/arrow-right-green.svg';
import starIcon from '@icons/msic-icon.svg';
import eyeBlackIcon from '@icons/eye-black.svg';
import Button from '@Shared/Button/Button';
import { NavLink } from 'react-router-dom';
import { currentTeamImages } from './legalTeamData';

const LegalBriefSection = () => {
  const statisticsData = [
    { text: 'motion types', value: '100+' },
    { text: 'Increase in efficiency', value: '42%' },
    { text: 'AI generated draft accuracy', value: '75%' },
  ];

  return (
    <section className="legal-brief-section-container">
      <div className="legal-brief-container">
        <h2 className="legal-brief-sub-heading">Effortless Legal Document Creation with Advanced AI Technology</h2>
        <h1 className="legal-brief-heading">Drafting Entire Briefs with Atticus AI</h1>

        <div className="features-brief-container">
          <div className="features-row">
            <div className="features-card-container">
              <div className="summary-contant-container">
                <div className="summary-card-top-image-wrapper">
                  <img src={starIcon} alt="Atticus AI Recommends" className="card-image" />
                </div>
                <div className="summary-contant">
                  <Button className="summary-show-more-btn button-disabled">
                    <img className="summmary-eye-black-icon" src={eyeBlackIcon} alt="shape down" />
                    <span className="btn-text-font"> Summary</span>
                  </Button>
                  <p className="summary-contant-paragraph">
                    From 1789 to 2023, no former, or current, President faced criminal charges for his official acts— for good reason. The President cannot
                    function, and the Presidency itself cannot retain its vital independence, if the President...
                  </p>
                </div>
              </div>
            </div>
            <FeatureCard
              title="Effortless Brief Drafting"
              content="Effortlessly Draft Legal Briefs with Atticus AI. Say goodbye to writer’s block or hours spent crafting rote templates. With Atticus AI's advanced AI, you can generate comprehensive briefs quickly and accurately, saving valuable time and resources. Atticus AI analyzes case details, identifies relevant legal arguments, and generates polished briefs customized to your case specifics, ensuring thorough and effective legal representation. That frees you up to do what you do best - being a star attorney."
              icon={pdfIcon}
            />
          </div>
          <div className="features-row">
            <FeatureCard
              title="Comprehensive Legal Analysis"
              content="Harness the Power of AI for Comprehensive Legal Analysis. Atticus AI analyzes case data, identifies relevant legal precedents, and generates detailed arguments tailored to your case specifics. Atticus AI ensures thorough and effective legal representation. With Atticus AI by your side, you can confidently navigate complex legal challenges, armed with insightful analysis and persuasive arguments."
              icon={legalAnalysisIcon}
            />
            <div className="features-card-container">
              <div className="atticus-recommends">
                <div className="atticus-recommends-card-image-wrapper">
                  <img src={rightThreeLinesIcon} alt="Atticus Recommends" className="card-image" />
                </div>
                <div className="atticus-recommends-content">
                  <h2 className="atticus-recommends-subtitle">Atticus AI Recommends</h2>
                  <Button className={'button--outline'}>Motion to Extend Time</Button>
                  <Button className={'button--outline'}>Motion to Suppress Evidence</Button>
                  <Button className={'button--outline'}>Motion to Dismiss Indictment</Button>
                  <Button className="recommends-show-more-btn button-disabled">
                    <div className="show-more-btn">
                      <span> Show more </span>
                      <img className="shape-arrow-down" src={arrowDownIcon} alt="shape down" />
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="features-row">
            <div className="features-card-container">
              <div className="legal-team-contant-container">
                <div className="legal-team-card-top-image-wrapper">
                  <img src={leftThreeLinesIcon} alt="Atticus AI Recommends" className="card-image" />
                </div>
                <div className="legal-team-contant">
                  <div className="team-images">
                    {currentTeamImages?.map((image, index) => (
                      <img key={index} src={image} style={{ zIndex: currentTeamImages.length - index }} alt={`Engineer ${index + 1}`} />
                    ))}
                  </div>
                  <div>
                    <h4>Your Legal Team</h4>
                    <span>{currentTeamImages.length} members</span>
                  </div>

                  <NavLink to={'#'} className={'button-disabled'}>
                    Manage Team <img src={arrowRightIcon} alt="forward" />
                  </NavLink>
                </div>
              </div>
            </div>
            <FeatureCard
              title="Seamless Collaboration"
              content="Collaborate Seamlessly for Optimal Results. Atticus AI facilitates seamless collaboration between legal professionals, allowing teams to work together efficiently to refine, review, and finalize legal briefs, ensuring accuracy and cohesion. With Atticus AI as your virtual assistant, you can streamline your workflow, enhance collaboration, and achieve optimal outcomes in your legal endeavors."
              icon={seamlessCollabrationIcon}
            />
          </div>
        </div>

        <div className="legal-brief-stats-container">
          <div className="legal-brief-stats">
            {statisticsData?.map((stat, index) => (
              <div key={index} className="stat-item">
                <h3>{stat.value}</h3>
                <p>{stat?.text}</p>
              </div>
            ))}
          </div>
          <p className="legal-brief-stats-description">
            With Atticus AI as your virtual legal assistant, you can access personalized motion recommendations based on comprehensive analysis and insights,
            empowering you to make informed decisions and achieve optimal outcomes for your clients. Explore the innovative capabilities of Atticus AI and
            unlock new possibilities in your legal practice.
          </p>
        </div>
      </div>
    </section>
  );
};

export default LegalBriefSection;
