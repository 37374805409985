import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import arrowRight from './../../../assets/images/atticus/icons/arrow-right-black.svg';
import Button from '../Button/Button';
import './Accordion.css';

const AccordionSectionHeader = ({ title, active }) => {
  return (
    <div style={{ width: '100%' }} className={`accordion-section-header ${active ? 'active' : ''}`}>
      {title}
    </div>
  );
};

const Accordions = ({ data, onSelect, isButton = false, isImage = false }) => {
  const [selectedSuite, setSelectedSuite] = useState(0);

  const handleAccordionClick = (index) => {
    setSelectedSuite(index);
    if (onSelect) {
      onSelect(index);
    }
  };

  return (
    <div className="software-suites-content">
      <Accordion defaultActiveKey={[selectedSuite.toString()]} alwaysOpen={false} className="custom-accordion">
        {data.map((suite, index) => (
          <Accordion.Item key={index} eventKey={index.toString()} onClick={() => handleAccordionClick(index)} className="custom-accordion-item">
            <Accordion.Header>
              <AccordionSectionHeader title={suite.title} active={selectedSuite === index} />
            </Accordion.Header>
            <Accordion.Body className="custom-accordion-body">
              <p>{suite.description}</p>
              {isButton && (
                <Button className="button--primary button-disabled " type="submit">
                  Visit Website <img src={arrowRight} alt="Atticus AI Recommends" />
                </Button>
              )}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
      {isImage && (
        <div className="software-image">
          <img src={data[selectedSuite].image} alt={data[selectedSuite].title} />
        </div>
      )}
    </div>
  );
};

export default Accordions;
