export const formatDate = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return `${day} ${getMonthName(month)} ${year}`;
};

export const getMonthName = (month) => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return monthNames[parseInt(month, 10) - 1];
};

export const formatSlashDate = (date) => {
  if (!date) return '';
  const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(
    new Date(date)
  );
  return formattedDate;
};

export const parseDate = (dateString) => {
  const [month, day, year] = dateString.split('/');
  const date = new Date(`${year}-${month}-${day}`);
  return isNaN(date) ? null : date;
};

export const formatToYYYYMMDD = (date) => {
  if (!date) return null;
  const [month, day, year] = date.split('/');
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

export const formatMMDDYYYY = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
};

export const getTodayYYYYMMDD = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
};

export const isTimeStampWithinLast30Days = (timestampInSeconds) => {
    const date = new Date(timestampInSeconds * 1000); // multiplies by 1000 to convert to milliseconds
    const now = new Date();  
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(now.getDate() - 30);
    return date >= thirtyDaysAgo && date <= now;
}