import React from "react";
import "./Message.css";
import { ReactComponent as AtticusIcon } from "@icons/atticus-chat-room-frame.svg";
import { ReactComponent as Dislike } from "@icons/dislike.svg";
import { ReactComponent as Reload } from "@icons/reload.svg";
import { ReactComponent as Clipboard } from "@icons/clipboard.svg";
import { ReactComponent as Speaker } from "@icons/speaker.svg";
import TypewriterText from "@Shared/TypewriterText/TypewriterText";

const Message = ({ text, userIsAuthor, speed = 0 }) => {
  if (userIsAuthor) {
    return (
      <div className="chat-research-message user-chat-message">
        <p>{text}</p>
      </div>
    );
  } else {
    return (
      <div className="chat-research-message">
        <AtticusIcon className="atticus-icon" />
        <p>
          <TypewriterText text={text} speed={speed} />
        </p>
        <div className="chat-research-message-actions">
          <Speaker className="speaker-icon" />
          <Clipboard className="clipboard-icon" />
          <Reload className="reload-icon" />
          <Dislike className="dislike-icon" />
        </div>
      </div>
    );
  }
};

export default Message;
