import React, { useContext, useState } from 'react';
import ChatContext from './ChatContext';

const ChatProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [workflow, setWorkflow] = useState(null);
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [currentCase, setCurrentCase] = useState(null);
  const [currentThread, setCurrentThread] = useState(null);
  const [parentMessageId, setParentMessageId] = useState(null);
  const [conversationId, setConversationId] = useState(null);
  const [conversations,  setConversations] = useState([]);

// Example:
// {
//   "messageId": "5a5daeb3-6e64-4cd1-8aad-3901ac6bdb5f",
//   "parentMessageId": "00000000-0000-0000-0000-000000000000",
//   "conversationId": "00000000-0000-0000-0000-000000000000",
//   "sender": "User",
//   "text": "tell me a joke",
//   "isCreatedByUser": true,
//   "tokenCount": 8
// }

  const addRequestMessage = (message, workflowType="global", isDefaultSelected=false ) => {
    setMessages((prevMessages) => {
      return [...prevMessages, {
        id: prevMessages.length + 1,
        text: message.text,
        type:workflowType,
        userIsAuthor: true,
        dateCreated: new Date().toISOString(),
        messageId: message?.messageId,
        parentMessageId: message?.parentMessageId,
        sender: message?.sender,
        isCreatedByUser: message.sender === "User",
        tokenCount: message?.tokenCount,
        conversationId: message?.conversationId,
      }];
    });
  }

  // Example:
//   {
//     "messageId": "04e6b61d-76b2-4420-ade9-f347da11eb2f",
//     "conversationId": "00000000-0000-0000-0000-000000000000",
//     "parentMessageId": "5a5daeb3-6e64-4cd1-8aad-3901ac6bdb5f",
//     "isCreatedByUser": false,
//     "model": "gpt-4",
//     "sender": "GPT-4",
//     "text": "Why don't scientists ever trust atoms? \n\nBecause they make up everything!",
//     "promptTokens": 11,
//     "endpoint": "openAI",
//     "finish_reason": "stop"
// }

  const addResponseMessage = (message , workflowType="global", isDefaultSelected=false) => {
    setMessages((prevMessages) => {
      return [...prevMessages, {
        id: prevMessages.length + 1,
        text: message.text, 
        type:workflowType,
        userIsAuthor: false,
        dateCreated: new Date().toISOString(),
        speed: 50,
        messageId: message?.messageId,
        conversationId: message?.conversationId,
        parentMessageId: message?.parentMessageId,
        sender: message?.sender,
        isCreatedByUser: message.sender === "User",
        tokenCount: message?.tokenCount,
        model: message?.model,
        promptTokens: message?.promptTokens,
        endpoint: message?.endpoint,
        finishReason: message?.finish_reason
      }];
    });
  }

  return (
    <ChatContext.Provider value={{
      messages,
      setMessages,
      workflow,
      setWorkflow,
      waitingForResponse,
      setWaitingForResponse,
      addRequestMessage,
      addResponseMessage,
      currentCase,
      setCurrentCase,
      currentThread,
      setCurrentThread,
      parentMessageId,
      setParentMessageId,
      conversationId,
      setConversationId,
      conversations,
      setConversations,
    }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatProvider;
