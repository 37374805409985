import React from 'react';
import './TimelineItem.css';
import documentGreenIcon from '@icons/document-green-icon.svg';
import documentRedIcon from '@icons/document-red-icon.svg';
import judgeDecisionIcon from '@icons/judge-black-icon.svg';
import DocumentItem from '../DocumentItem/DocumentItem';
import { formatMMDDYYYY } from '@lib/utils/dateFormatHelpers';

const TimelineItem = ({ date, role, documents, documentExists, summaryS3Url, docketEntryId, caseId }) => {
    const documentItems = documents
    ? [
        {
          title: documents.split(' ').slice(0, 5).join(' '), 
          description: documents,
        },
      ]
    : [];

    let iconSrc;

    if (role === 'defendant') {
        iconSrc =  documentGreenIcon 
    } else if (role === 'prosecutor') {
        iconSrc = documentRedIcon;
    } else if (role === 'court') {
        iconSrc = judgeDecisionIcon;
    } else {
        iconSrc = documentRedIcon;
    }

    return (
        <div className="timeline-item">
            <div className="timeline-icon-container">
                <img src={iconSrc} alt="Document Icon" className="timeline-icon" />
            </div>
            <div className="timeline-content">
                <div className="timeline-header">
                    <span className="timeline-date">{date ? formatMMDDYYYY(date) : ""}</span>
                    <span className={`timeline-role `}>{role ? role.charAt(0).toUpperCase() + role.slice(1) : ""}</span>
                </div>
                <div className="document-item-card document-list">
                    {documentItems.length > 0 ? (
                        documentItems.map((doc, index) => (
                            <DocumentItem
                                key={index + new Date()}
                                title={doc.title}
                                description={doc.description}
                                summary=""
                                role={role}
                                documentExists={documentExists === 1}
                                summaryS3Url={summaryS3Url}
                                uploadFileStatus=""
                                docketEntryId={docketEntryId}
                                caseId={caseId}
                            />
                        ))
                    ) 
                    : (
                        <DocumentItem
                            title=""
                            description="" 
                            role={role} 
                            summary=""
                            documentExists={documentExists === 1}
                            summaryS3Url={summaryS3Url}
                            uploadFileStatus="" />
                    )
                    }
                </div>
            </div>
        </div>
    );
};

export default TimelineItem;
