import { toast } from "react-toastify";
import { generateDocketRecommendations } from "../apis/pacer-api";
import { getUser } from "../apis/resources/user";

export const getOpenCases = (casesArray) => {
  return casesArray.filter(c => c.status === 'open');
}

export const getClosedCases = (casesArray) => {
  return casesArray.filter(c => c.status === 'closed');
}

export const titleToAbbreviation = (caseTitle) => {
  const words = caseTitle.trim().split(/\s+/);
  const firstLetters = words.map((word) => {
    if (word === 'v.s') {
      return 'vs';
    } else {
      return word.length <= 2 ? word : word[0];
    }
  });
  return firstLetters.filter(Boolean).join(' ');
}

/**
 * Retries an API call function multiple times until it succeeds or the retry limit is reached.
 * The first attempt will be made immediately, and subsequent attempts will have a 30-second delay.
 * 
 * @param {Function} callAPIFunction - The API function to be called (e.g., a function that makes an HTTP request).
 * @param {Array|*} [params=[]] - Parameters to pass to the API function. If only a single value is provided, it will be converted into an array.
 * @param {number} [retryLimit=Infinity] - The maximum number of retry attempts. Defaults to no limit (Infinity).
 * @param {boolean} [showToast=false] - A flag to control whether to display a toast notification upon failure after exhausting retry attempts.
 * @param {number} [delay=30000] - The delay (in milliseconds) between retry attempts after the first one. Default is 30000ms (30 seconds).
 * 
 * @returns {Object|null} - Returns the API response if successful, otherwise null if retry attempts are exhausted.
 */
export const retryApiCall = async (
  callAPIFunction,
  params = [],
  loadingMessage,
  setLoadingMessage = '',
  retryLimit = Infinity,
  showToast = false,
  delay = 30000
) => {
  let response;
  let attempts = 0;

  if (!Array.isArray(params)) {
    params = [params];
  }

  const loadingMessages = [
    "Waiting for response...",
    "Checking database...",
    "Processing request...",
  ];

  const delayFunction = async (ms) => {
    if (setLoadingMessage) {
      let remainingTime = ms / 1000; 
      const interval = 1000; 
      const messageSwitchInterval = 8; 
      let messageIndex = 0;

      return new Promise((resolve) => {
        const intervalId = setInterval(() => {
          remainingTime -= 1;

          
          if (remainingTime % messageSwitchInterval === 0) {
            setLoadingMessage(
              `${loadingMessages[messageIndex]}`
            );
            messageIndex = (messageIndex + 1) % loadingMessages.length; 
          }

          if (remainingTime <= 0) {
            clearInterval(intervalId);
            setLoadingMessage('Loading');
            resolve();
          }
        }, interval);

        setTimeout(() => {
          clearInterval(intervalId);
          setLoadingMessage('Loading');
          resolve();
        }, ms);
      });
    } else {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
  };

  do {
    attempts++;

    if (attempts > 1) {
      await delayFunction(delay);
    }

    try {
      response = await callAPIFunction(...params);

      if (response?.success) {
        return response;
      }
    } catch (error) {
      console.error(`Error during ${callAPIFunction.name} on attempt ${attempts}:`, error);
    }

    if (attempts >= retryLimit) {
      if (showToast) {
        toast.error(
          'Unable to add a case. Please wait and try again. If this issue persists, please contact support at help@atticusai.org'
        );
      }
      break;
    }
  } while (!response?.success);

  return null;
};

export const fetchMotion = async (userId, caseId, appId, loadingMessage, setLoadingMessage) => {
  try {
    const response = await retryApiCall(
      generateDocketRecommendations,
      [userId, caseId, appId],
      loadingMessage,
      setLoadingMessage,
    );
    
    if (response?.success === true  && response?.data?.length > 0) {
      return response.data.map((motion) => ({
        name: motion?.name,
        value: motion?.value,
      })).filter((motion) => motion?.name !== null);
    } else {
      console.warn('No motions found for caseId:', caseId);
    }
  } catch (error) {
    console.error('Error fetching motion types:', error);
    throw error;
  }
};

export const updateMotionsUI = (motions, caseId) => {
  const savedMotionsKey = `motionTypes_${caseId}`;
  const isFetchedKey = `motionTypesFetched_${caseId}`;

  localStorage.setItem(savedMotionsKey, JSON.stringify(motions));
  localStorage.setItem(isFetchedKey, 'true');
  
  return motions;
};

export const cleanupLocalStorage = (caseId) => {
  const savedMotionsKey = `motionTypes_${caseId}`;
  const isFetchedKey = `motionTypesFetched_${caseId}`;
  const savedSummaryKey = `caseSummary_${caseId}`;

  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith('motionTypes_') && key !== savedMotionsKey) {
      localStorage.removeItem(key);
    }
    if (key.startsWith('motionTypesFetched_') && key !== isFetchedKey) {
      localStorage.removeItem(key);
    }
    if (key.startsWith('caseSummary_') && key !== savedSummaryKey) {
      localStorage.removeItem(key);
    }
  });
};


export const isUserObjectAssignedToCaseId = async (caseId, email) => {
  const response = await getUser(email);

  if (response?.success) {
    const assignedCases = response?.data?.user?.assignedCases;

    if (assignedCases?.includes(caseId)) {
      return true;
    }
  }
  return false;
};