import React, { useContext, useState } from 'react';
import Button from '@Shared/Button/Button';
import GenerateMotionContext from './GenerateMotionContext';

const GenerateMotionButton = () => {
  const { setShowModal } = useContext(GenerateMotionContext);
  const { selectedMotion } = useContext(
    GenerateMotionContext
  );
  const handleClick = () => {
    if (selectedMotion) {
      setShowModal(true)
    }
  }

  return (
    <Button
      onClick={handleClick}
      className="generate-recommendation-button"
      disabled={selectedMotion !== null ? false : true}
    >
      Generate Motion
    </Button>
  );
};

export default GenerateMotionButton;
