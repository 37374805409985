import { userTypes, authTypes } from '../types';

const initialState = JSON.parse(localStorage.getItem('user')) || null;

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.LOG_IN:
      return action.payload.user;
    case authTypes.LOG_OUT:
      return null;
    case userTypes.GET_ONE:
    case userTypes.SET_USER:
      return action.payload;
    case userTypes.UPDATE_ONE:
      return action.payload;
    default:
      return state;
  }
}

export default userReducer;
