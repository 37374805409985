import React, { useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './GenerateMotionModal.css';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loading from '@Shared/Loader/Loader';

import MotionModalPage1 from './GenerateMotionModalPages/MotionModalPage1';
import MotionModalPage2 from './GenerateMotionModalPages/MotionModalPage2';
import MotionModalPage3 from './GenerateMotionModalPages/MotionModalPage3';
import MotionModalPage4 from './GenerateMotionModalPages/MotionModalPage4';
import GenerateMotionContext from '../GenerateMotionContext';
const GenerateMotionModal = () => {
  const { showModal, setShowModal, closeModal } = useContext(
    GenerateMotionContext
  );
  const caseId = useParams().id;
  const user = useSelector((state) => state.user);

  const handleShow = () => setShowModal(true);
  const handleClose = () => closeModal();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [documentName, setDocumentName] = useState(null);
  const [motionId, setMotionId] = useState(null);
  const [motionType, setMotionType] = useState(null);
  const [citations, setCitations] = useState([]);
  const [processedCitations, setProcessedCitations] = useState(null);
  const [output, setOutput] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [blob, setBlob] = useState(null);
  const [loading, setLoading] = useState(false);

  const advancePage = (newPageIndex = false) => {
    if (newPageIndex) {
      console.log('advancing page to', newPageIndex);

      setCurrentPageIndex(newPageIndex);
      return;
    }
    console.log('advancing page to', currentPageIndex + 1);
    setCurrentPageIndex(currentPageIndex + 1);
  };

  return (
    <div>
      {showModal && (
        <div className="generate-motion-modal-overlay">
          <Modal
            show={handleShow}
            onHide={handleClose}
            className="generate-motion-modal"
          >
            {loading && <Loading /> }
            <MotionModalPage1
              handleClose={handleClose}
              currentPageIndex={currentPageIndex}
              pageIndex={0}
              advancePage={advancePage}
              user={user}
              caseId={caseId}
              setDocumentName={setDocumentName}
              setMotionId={setMotionId}
              citations={citations}
              setCitations={setCitations}
              setLoading={setLoading}
            />
            <MotionModalPage2
              handleClose={handleClose}
              currentPageIndex={currentPageIndex}
              pageIndex={1}
              advancePage={advancePage}
              setCurrentPageIndex={setCurrentPageIndex}
              citations={citations}
              user={user}
              caseId={caseId}
              documentName={documentName}
              motionId={motionId}
              setDownloadUrl={setDownloadUrl}
              setLoading={setLoading}
            />
            <MotionModalPage3
              handleClose={handleClose}
              currentPageIndex={currentPageIndex}
              pageIndex={2}
              advancePage={advancePage}
              setLoading={setLoading}
            />
            <MotionModalPage4
              handleClose={handleClose}
              currentPageIndex={currentPageIndex}
              pageIndex={3}
              advancePage={advancePage}
              downloadUrl={downloadUrl}
              setDownloadUrl={setDownloadUrl}
              setBlob={setBlob}
              documentName={documentName}
              caseId={caseId}
            />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default GenerateMotionModal;
