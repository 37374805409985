export const getParamsFromConversationId = (conversationId) => {
  const result = {};
  const pairs = conversationId.split('|');

  pairs.forEach(pair => {
    const [key, value] = pair.split(':');
    result[key] = value;
  });

  return result;
}