import axios from 'axios';
import { getAuthHeaders, graphQlUrl } from '../utilities';

export const getThread = async (userId, caseId) => {
  const threadName = `${userId}_${caseId}`;
  try {
    const query = `
      query MyQuery {
        caseThreadByThreadName(threadName: "${threadName}") {
          threadId
          caseId
          userId
          appId
          threadDetails
          isGeneralThread
          threadName
        }
      }
    `;


    const encodedQuery = encodeURIComponent(query); // Encode the query for safe URL usage
    const URL = `${graphQlUrl}${encodedQuery}`;
    // const URL = `https://atticusai.com/graphql/#query=${encodedQuery}`;
    const response = await axios.get(URL);
    return {
      success: true,
      data: response.data.data
    }; // Return the thread data
  } catch (error) {
    console.error('Error getting thread:', error);
    return { success: false, message: error.message };
  }
};