import React, { useState, useContext } from 'react';
import GenerateMotionModalPage from './GenerateMotionModalPage';
import GenerateMotionContext from '../../GenerateMotionContext';

const MotionModalPage3 = ({ pageIndex }) => {
  const { processingSteps } = useContext(GenerateMotionContext);
  return (
    <GenerateMotionModalPage
      title="Generating Your Motion"
      pageIndex={pageIndex}
    >
      {processingSteps.map((step, index) => (
        <div className="generating-motion-step" key={index}>
          <step.status />
          <p>{step.text}</p>
        </div>
      ))}
    </GenerateMotionModalPage>
  );
};

export default MotionModalPage3;
