import React, { useEffect, useState } from 'react';
import { Menu, MenuButton, MenuList, MenuItem, Button } from '@chakra-ui/react';
import { FaEdit, FaRetweet } from 'react-icons/fa';
import './LastDocketEntry.css';
import actionIcon from '@icons/more-line.svg';
import DataTable from '@Shared/DataTable/DataTable';
import { useParams } from 'react-router-dom';
import { getDocketEntriesForCase } from '@lib/apis/resources/case';
import { formatMMDDYYYY } from '@lib/utils/dateFormatHelpers';

const LastDocketEntry = () => {
  const [docketEntries, setDocketEntries] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id: caseId} = useParams();

  const headings = ['Date', 'Filed By', 'Filing', 'Description'];

  const handleEdit = (entry) => {
    console.log('Edit:', entry);
  };

  const handleReschedule = (entry) => {
    console.log('Reschedule:', entry);
  };

  useEffect(() => {
    const fetchDocketEntries = async () => {
      setLoading(true)
      try {
        const response = await getDocketEntriesForCase(caseId);
        if (response?.success === true && Array.isArray(response?.data)) {
          const sortedDocketEntries = response?.data?.sort(
            (a, b) => new Date(b.dateFiled) - new Date(a.dateFiled)
          );
          const latestDocketEntry = sortedDocketEntries[0];
          setDocketEntries([latestDocketEntry]);
        }
      } catch (error) {
        console.log("Error while fetching caseTimeLine: ", error)
      } finally {
        setLoading(false);
      }
    };

    fetchDocketEntries();
  }, []);

  const renderRow = (entry) => {
    const date = entry?.dateFiled ? formatMMDDYYYY(entry.dateFiled) : '';
    const filedBy = entry?.classification ? entry.classification.charAt(0).toUpperCase() + entry.classification.slice(1) : '';
    const descriptionWords = entry?.description ? entry.description.split(' ') : [];
    const filing = descriptionWords?.slice(0, 6).join(' ') + (descriptionWords.length > 6 ? '...' : '');
    const description = entry?.description ? entry?.description : '' ;

    return  (
    <>
      <td className='last-docket-entry-date'>{date}</td>
      <td className='last-docket-entry-date'>{filedBy}</td>
      <td className='last-docket-entry-filing'>{filing}</td>
      <td>{description}</td>
      <td className='last-docket-entry-menu-icon'>
        <Menu>
          <MenuButton
            as={Button}
            p={0}
            variant="outline"
            border="none"
            backgroundColor="transparent"
            className="menu-button"
          >
            <img className="action-icon" src={actionIcon} alt="View" />
          </MenuButton>
          <MenuList className="custom-menu-list">
            <MenuItem
              icon={<FaEdit />}
              onClick={() => handleEdit(entry)}
              className="custom-menu-item"
            >
              Edit
            </MenuItem>
            <MenuItem
              icon={<FaRetweet />}
              onClick={() => handleReschedule(entry)}
              className="custom-menu-item"
            >
              Reassign
            </MenuItem>
          </MenuList>
        </Menu>
      </td>
    </>
  );
};

  return (
    <div className="last-docket-entry white-background rounded-border">
      <h3>Last Docket Entry</h3>
      {loading ? (
        <p>No Docket entry available.</p>
      ) : (
        <DataTable
        headings={headings}
        data={docketEntries}
        renderRow={renderRow}
      />
      )}
    </div>
  );
};

export default LastDocketEntry;
