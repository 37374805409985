import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import axios from 'axios';

export const generatePresignedURL = async (key, caseId) => {
  try {
    const s3Client = new S3Client({
      region: process.env.REACT_APP_API_REGION,
      credentials: {
        accessKeyId: process.env.REACT_APP_API_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_API_SECRET_KEY,
      },
    });

    const command = new GetObjectCommand({
      Bucket: `${process.env.REACT_APP_API_BUCKET_NAME}${caseId}`,
      Key: key,
    });

    const url = await getSignedUrl(s3Client, command, { expiresIn: 3600 });
    return url;
  } catch (error) {
    console.error('Error generating pre-signed URL:', error);
    return null;
  }
};

export const handleGenerateFile = async (key, caseId, isDocumentSummary=true) => {
 
  const apiURL = await generatePresignedURL(key, caseId);

  const response = await axios(apiURL);
  const data = response?.data;

  if (isDocumentSummary) {
  const value = data.split("value='");
    if (value.length > 1) {
        return value[1].split("')")[0];
    } else {
      const value = data.split('value="');
      if (value.length > 1) {
        return value[1].split('")')[0];
    }
    }
  } else if (data) {
      return data;
}
  
    return null;
};

export const generateKeyFromSummaryUrl = (s3Url) => {
    return s3Url.split('s3://')[1].split('/').slice(1).join('/')
}

export const getFileUrl = async (key, userId, caseId) => {
  try {
    const s3Client = new S3Client({
      region: process.env.REACT_APP_API_REGION,
      credentials: {
        accessKeyId: process.env.REACT_APP_API_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_API_SECRET_KEY,
      },
    });

    const command = new GetObjectCommand({
      Bucket: `${userId}-${caseId}`,
      Key: key,
    });

    const url = await getSignedUrl(s3Client, command, { expiresIn: 3600 });
    return url;
  } catch (error) {
    console.error('Error generating pre-signed URL:', error);
    return null;
  }
}