import React, { useContext } from 'react';
import './Research.css';
import Header from '../Shared/Header/Header';
import Message from './Message/Message';
import ChatContext from '../ChatContext';
import ChatInputBox from '../Shared/ChatInputBox/ChatInputBox';
import ProcessingMessageBubble from './ProcessingMessageBubble/ProcessingMessageBubble';


// TODO: Extract these functions to a utility file
const prettyDate = (dateString) => {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'short', day: '2-digit' };
  return date.toLocaleDateString('en-US', options);
}
const Research = () => {
  const { waitingForResponse, setWaitingForResponse, messages, addMessage } =
    useContext(ChatContext);

  return (
    <section className="chat-research-section">
      <Header />
      <div className="chat-research-container">
        <div className="chat-research-content">
          <p className="chat-research-date">{prettyDate(messages[0]?.updatedAt)}</p>
          {messages.map((message) => (
            <Message
              key={message.id}
              text={message.text || message.content[0]?.text?.value}
              userIsAuthor={message.sender === "User" || message.userIsAuthor}
            />
          ))}
        </div>
        {waitingForResponse && <ProcessingMessageBubble />}
        <ChatInputBox />
      </div>
      {/* Add Footer Here if needed */}
    </section>
  );
};

export default Research;
